import React from "react";

function Header() {
    return (
        <header>
            <h1 className="text-4xl">NFL Connections</h1>
        </header>
    );
}

export default Header;