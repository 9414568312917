import React from "react";

import { generateEmojiGrid } from "../../../lib/game-helpers";
import CountdownToNextPuzzle from "../../Countdown";
import ShareScoreButton from "../../ShareScore";
import BaseModal from "../BaseModal";
import { GameStatusContext } from "../../../providers/GameStatus";
import { PuzzleDataContext } from "../../../providers/PuzzleData";
import { Button } from "../../ui/button";

function ViewResultsModal() {
    const { submittedGuesses } = React.useContext(GameStatusContext);
    const { gameData } = React.useContext(PuzzleDataContext);

    return (
        <BaseModal
            title=""
            trigger={
                <Button variant="submit" className="w-full" children={"View Results"} />
            }
            initiallyOpen={false}
            showActionButton={false}
            footerElements={<ShareScoreButton buttonText={"Share Your Score!"} />}
        >
            <div className="flex flex-col place-content-center">
                <p className="text-center font-[600]">
                    Your Guesses Are Represented Below
                </p>
                <span className="text-center whitespace-pre mb-2">
                    {"\n"}
                    {generateEmojiGrid(gameData, submittedGuesses)}
                </span>
                <CountdownToNextPuzzle />
            </div>
        </BaseModal>
    );
}

export default ViewResultsModal;